<template>
  <div class="terms">
    <div class="header">
      <h1>Terms and conditions</h1>
    </div>
    <div class="first_p">
      <p>
        These terms and conditions of use (the “terms” or “Agreement”) govern
        our Kwikklik web, android and iOS platforms, which are managed by eBASE
        Africa. By accessing and/or using our sites, you are agreeing to be
        bound by these terms, all applicable laws and regulations, and any other
        applicable policies, terms and guidelines established by eBASE Africa
        and those of any third parties that host our sites, as amended from time
        to time. If you do not agree with all of these terms or cannot form a
        legally binding contract, you are not permitted to use our sites. We do
        not, in any way, adopt, endorse, or guarantee the accuracy of content
        posted by users on our site. We are not legally responsible for content
        posted by third parties on our sites. We do not endorse clicking on
        links posted by users, as these links may pose risks to your computer or
        take you to inappropriate sites. We may disclose your communications and
        activities on our sites in response to requests by any government
        agency.
      </p>
      <p>
        Those that have a business relationship with EBASE Africa and/or any
        other interests are responsible for adhering to any previous
        restrictions and/or obligations regarding information that may be deemed
        non-public. These terms in no way replace terms outlined in any other
        agreement executed between EBASE Africa and you.
      </p>
    </div>
    <div class="limations">
      <h1>Use Limitations and Guidelines</h1>
      <p>
        You agree to comply with the guidelines outlined herein with regard to
        any content that you post/share on our sites, and you agree to our right
        to moderate and delete any content at our sole discretion. You also
        agree that your activities on our sites will not violate any applicable
        law, -regulation or terms of any existing agreements you may have with
        eBASE Africa. Our sites may only be used for personal purposes. You may
        not reproduce, duplicate, copy, sell, resell, or exploit, for any
        commercial purposes, any portion of our sites or access to our sites.
        Any unauthorized use of our sites or misuse of any information posted to
        a site is strictly prohibited. Your use of our sites is subject to the
        following guidelines:
      </p>
      <div class="list">
        <p>
          <span>*</span>
          You agree that you are responsible for any content that you submit to
          our sites, including comments, photographs and other media.
        </p>
        <p>
          <span>*</span>
          Posting Content to Our sites:
        </p>
        <div class="sub_list">
          <p>
            <span>*</span>
            Stay relevant – stay on topic with relevant information that others
            may find useful.
          </p>
          <p>
            <span>*</span>
            Do not post advertisements.
          </p>
          <p>
            <span>*</span>
            Be respectful – maintain a friendly environment.
          </p>
          <p>
            <span>*</span>
            Do not post material that is inappropriate or that could reasonably
            be deemed defamatory, offensive or obscene.
          </p>
          <p>
            <span>*</span>
            No investment advice – do not post recommendations and/or advice
            about specific securities, products, or services.
          </p>
          <p>
            <span>*</span>
            Do not use these online spaces for illegal purposes or to breach any
            laws.
          </p>
          <p>
            <span>*</span>
            Do not harass, cause distress or inconvenience to, or infringe the
            rights of, another person.
          </p>
          <p>
            <span>*</span>
            Do not try to access administrator areas, features or functions of
            our sites without authorization.
          </p>
          <p>
            <span>*</span>
            Do not submit anything to our sites that will or may violate the
            rights of a third party, including but not limited to copyright,
            trademark, and/or privacy rights.
          </p>
          <p>
            <span>*</span>
            Do not use our sites or content on them in any way not permitted by
            these terms
          </p>
        </div>
        <p>
          <span>*</span>
          You agree that you shall not place false or misleading information on
          our sites or upload any computer programs—including but not limited to
          viruses, Trojan horses, worms, and/or time bombs—that may interfere
          with the proper operation of our sites.
        </p>
        <p>
          <span>*</span>
          We reserve the right to restrict users who don’t follow these
          guidelines, have online pages containing offensive material
          and/content, or for any other reason.
        </p>
        <p>
          <span>*</span>
          By posting any content on any site, you grant to eBASE Africa the
          irrevocable right to reproduce, distribute, publish, display, edit,
          modify, create derivative works from and otherwise use such content
          for any purpose, in any form, and on any media.
        </p>
        <p>
          <span>*</span>
          Our sites are intended solely for individuals who are age 13 or older.
          Any access to or use of our sites by anyone under 13 is unauthorized,
          unlicensed, and in violation of these terms. By accessing or using our
          sites, you represent and warrant that you are 13 or older.
        </p>
      </div>
    </div>

    <div class="privacy">
      <h1>Privacy Policy</h1>
      <p class="intro">Introduction</p>
      <p class="privacy_body">
        Our privacy policy will help you understand what information we collect
        at eBASE, , how eBASE uses it, and what choices you have. eBASE built
        the Kwikklik app as a free app. This SERVICE is provided by eBASE at no
        cost and is intended for use as is. If you choose to use our Service,
        then you agree to the collection and use of information in relation with
        this policy. The Personal Information that we collect are used for
        providing and improving the Service. We will not use or share your
        information with anyone except as described in this Privacy Policy. The
        terms used in this Privacy Policy have the same meanings as in our Terms
        and Conditions, which is accessible in our website, unless otherwise
        defined in this Privacy Policy
      </p>

      <p class="privacy_body">
        Information Collection and Use For a better experience while using our
        Service, we may require you to provide us with certain personally
        identifiable information, including but not limited to user name, email
        address, date of birth, language, region of origin, gender, location,
        pictures. The information that we request will be retained by us and
        used as described in this privacy policy. The app does use third party
        services that may collect information used to identify you
      </p>
      <p class="intro">Cookies</p>
      <p class="privacy_body">
        are files with small amount of data that is commonly used an anonymous
        unique identifier. These are sent to your browser from the website that
        you visit and are stored on your device’s internal memory.
      </p>
      <p class="privacy_body">
        This Services does not use these “cookies” explicitly. However, the app
        may use third party code and libraries that use “cookies” to collection
        information and to improve their services. You have the option to either
        accept or refuse these cookies, and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>
      <p class="intro">Location Information</p>
      <p class="privacy_body">
        Some of the services may use location information transmitted from
        users' mobile phones. We only use this information within the scope
        necessary for the designated service.
      </p>
      <p class="intro">Device Information</p>
      <p class="privacy_body">
        We collect information from your device in some cases. The information
        will be utilized for the provision of better service and to prevent
        fraudulent acts. Additionally, such information will not include that
        which will identify the individual user.
      </p>
      <p class="intro">Service Providers</p>
      <p class="privacy_body">
        We may employ third-party companies and individuals due to the following
        reasons: <br />
        To facilitate our Service; To provide the Service on our behalf; To
        perform Servicerelated services; or to assist us in analyzing how our
        Service is used. We want to inform users of this Service that these
        third parties have access to your Personal Information. The reason is to
        perform the tasks assigned to them on our behalf. However, they are
        obligated not to disclose or use the information for any other purpose.
      </p>
      <p class="intro">Security</p>
      <p class="privacy_body">
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security. No user gets to see the author of any reported
        case; since all cases are reported anonymously.
      </p>
      <br />

      <p class="privacy_body">
        Changes to This Privacy Policy We may update our Privacy Policy from
        time to time. Thus, you are advised to review this page periodically for
        any changes. We will notify you of any changes by posting the new
        Privacy Policy on this page. These changes are effective immediately,
        after they are posted on this page.
      </p>
      <div class="monitor">
        <h1>Monitoring</h1>
        <p>
          eBASE Africa has no obligation to monitor the use of our sites by
          users. You acknowledge and agree that eBASE Africa reserves the right
          to, and may from time to time, monitor any and all information
          transmitted or received through our sites for operational and other
          purposes. During monitoring, information may be examined, recorded,
          copied, and used for authorized purposes. Use of our sites constitutes
          consent to such monitoring. Furthermore, eBASE Africa reserves the
          right at all times to disclose any information posted on any portion
          of our sites as necessary to satisfy any law, regulation or
          governmental request, or to refuse to post, or to remove, any
          information or materials, in whole or in part, that in eBASE Africa
          sole and absolute discretion are objectionable or in violation of this
          Agreement.
        </p>
        <h1>Disclaimers</h1>
        <p>
          The materials on eBASE Africa sites are provided “as is.” eBASE Africa
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all warranties, including without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, and noninfringement of intellectual property or other
          violation of rights. Further, eBASE Africa does not warrant or make
          any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its sites or otherwise
          relating to such materials or on any sites linked to these sites.
          eBASE Africa does not guarantee that our sites will be without errors
          or viruses, or that any site defects will be corrected, even if eBASE
          Africa is aware of them. The information and content provided on our
          sites, including without limitation text, graphics and images, are for
          informational purposes only, not as an offer to sell or the
          solicitation for the purchase of stock of eBASE Africa, or any other
          issuer or company. eBASE Africa is not responsible for any content
          posted by users, including posts made by employees or agents who are
          not authorized administrators of our sites. Content posted by others
          is not edited by eBASE Africa and does not necessarily represent the
          views or positions of eBASE Africa. eBASE Africa is not liable for the
          acts or omissions of thirdparty service providers or hosts of the
          sites.
        </p>
        <h1>Limitations</h1>
        <p>
          The use of our sites is entirely at your own risk, and in no event
          shall eBASE Africa, its affiliates or its suppliers be liable for any
          direct, indirect, incidental, consequential, special, exemplary,
          punitive or any other monetary or other damages, fees, fines,
          penalties, or liabilities (including, without limitation, damages for
          loss of data or profit, or due to business interruption) arising out
          of or relating to our sites, your use or inability to use the content
          on our sites, even if eBASE Africa has been notified orally or in
          writing of the possibility of such damage. Your sole and exclusive
          remedy for dissatisfaction with our sites is to stop using our sites.
          eBASE Africa will not be responsible in any way for loss or damage
          that results from your failure to comply with these terms or other
          applicable guidelines, or from any technical, human or software errors
          or failures found within our sites. eBASE Africa may use third party
          providers in providing our sites. eBASE Africa is not liable for the
          acts or omissions of any such third-party service providers. Users
          must comply with any thirdparty provider’s terms.
        </p>
        <h1>Indemnification</h1>
        <p>
          You agree to indemnify, defend, and hold harmless eBASE Africa and its
          employees, and agents from any and all losses, damages, liabilities,
          and costs of every nature (including without limitation judgments,
          expenses and reasonable attorneys’ fees and costs) incurred by any of
          them in connection with any claim, damage, or loss related to your use
          of our sites or any materials contained on our sites, or the breach or
          alleged breach by you of these terms.
        </p>
        <h1>Third-Party Links</h1>
        <p>
          eBASE Africa has not reviewed all of the third-party sites linked to
          its sites and is not responsible for the contents of any such linked
          site. The inclusion of any third-party link does not imply endorsement
          by eBASE Africa. Use of any such linked web site is at the user’s own
          risk. You understand that when going to a third-party web site, that
          site is governed by the third party’s privacy policy and terms of use,
          and the third party is solely responsible for the content and
          offerings presented on its website.
        </p>
        <h1>Site Modification or Termination</h1>
        <p>
          eBASE Africa may modify the features, content and functionality of its
          sites, or terminate entirely any of its sites, at any time without
          prior notice or liability to you. Modification or termination of these
          terms will not affect, or result in the termination or modification,
          of any other existing agreements you may have with eBASE Africa.
        </p>
        <h1>Social Media Terms of Use Modifications</h1>
        <p>
          eBASE Africa may revise these terms of use for its sites at any time
          without notice. These terms of use may be updated at any time and/or
          each time a social networking site updates their terms of use. By
          using these sites, you are agreeing to be bound by the then current
          version of these terms of use. Any modifications to these terms shall
          be effective as of the date posted. We encourage you to periodically
          review these terms.
        </p>
        <h1>Relationship</h1>
        <p>
          Your accessing of our sites in no way shall be deemed to create an
          agency or employee-employer relationship of any kind between you and
          eBASE Africa, nor do these terms extend rights to any third party. You
          also agree that in the course of your accessing and using our sites,
          that no relationship is formed between you and eBASE Africa that is
          either confidential or fiduciary in nature beyond any existing
          relationship you may have with us.
        </p>
        <h1>Termination of Use</h1>
        <p>
          eBASE Africa may, in its sole discretion, terminate your use of our
          sites at any time. eBASE Africa reserves the right to change, suspend
          or discontinue all or any aspects of our sites at any time without
          prior notice.
        </p>
        <br />
        <p>
          Contact Us If you have any questions or suggestions about our Privacy
          Policy, do not hesitate to contact us. Contact Information : Email :
          <a href="mailto: : okwen@ebaseafrica.org">okwen@ebaseafrica.org</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.terms {
  font-family: "Roboto", sans-serif;
}

.header {
  margin: 40px 40px 0px 40px;
}
.header h1 {
  font-size: 1.4rem;
  color: rgb(54, 54, 194);
  font-style: italic;
  letter-spacing: 1px;
  border-top: 2px solid rgb(54, 54, 194);
  border-bottom: 2px solid rgb(54, 54, 194);
  padding: 10px;
  font-weight: bold;
}
.first_p {
  margin: 20px 10px;
  font-style: 0.6rem;
  text-align: left;
}
.limations p {
  font-style: 0.6rem;
  text-align: left;
  margin: 3px 10px;
}
.limations h1 {
  text-align: left;
  font-size: 1.3rem;
  margin-left: 10px;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: black;
  margin: 10px;
  border-radius: 50%;
}
.list {
  text-align: left;
  font-style: 0.6rem;
  margin: 3px 10px;
}
.privacy h1 {
  text-align: left;
  font-size: 1.3rem;
  margin: 10px 10px;
}
.intro {
  text-align: left;
  color: rgb(54, 54, 194);
  margin-left: 10px;
}
.privacy_body {
  font-style: 0.6rem;
  text-align: left;
  margin: 3px 10px;
}
.monitor h1 {
  text-align: left;
  font-size: 1.3rem;
  margin: 10px 10px;
}
.monitor p {
  font-style: 0.6rem;
  text-align: left;
  margin: 3px 10px;
}
.list p {
  letter-spacing: 0px;
}
.list p span {
  font-weight: bold;
  /* margin-left: 15px; */
}
.sub_list {
  margin: 0px 20px;
}
</style>