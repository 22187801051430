export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour depuis "])},
  "Nav": {
    "gbvcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GBV Cases"])},
    "ReportAbuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Abuse"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])}
  },
  "popup": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Request"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Request"])},
    "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Research"])},
    "vue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Research"])},
    "edu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Create"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reports"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "header": {
    "anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reply"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "rating": {
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Rate this App"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a review"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND"])}
  },
  "delete": {
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete ?"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "btn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "report": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Type"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Abuse"])},
    "pysical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["physical violence"])},
    "options": {
      "psychological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["psychological violence"])},
      "stalking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stalking"])},
      "mariage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forced marriages"])},
      "sexual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sexual violence"])},
      "rape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rape"])},
      "mutilation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female genital mutilation"])},
      "sterilisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forced sterilisation"])},
      "harassement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sexual harassement"])},
      "abortion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forced abortion"])}
    },
    "option2": {
      "Prentive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Prentive Paliatives"])},
      "Consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Legal Consultant"])},
      "Medical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Medical Doctor"])},
      "Psychologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Psychologist"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
    }
  },
  "jobSeekers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Job"])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "options": {
      "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rider"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "psychologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychologist"])},
      "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Consultant"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Worker"])}
    }
  },
  "education": {
    "Nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Content"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
      "readM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])}
    },
    "updates": {
      "lable1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Education"])},
      "lable2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "la4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
    }
  },
  "demandes": {
    "clinical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande Clinical help"])},
    "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand Legal consultant"])},
    "bike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand Bike"])},
    "Psychological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand a Psychological Health specialist"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "myRequest": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update My Requests"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "help": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Health Specialist"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychological Health Specialist"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Consultants"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bike Riders"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Requests"])}
  },
  "professionals": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users who can offer service"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am:"])},
    "h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on: "])},
    "nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals"])},
      "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])}
    }
  },
  "profile": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Update"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "label5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital Status"])},
    "label6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "label7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
    "label8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region of residence"])},
    "label9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
    "label10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "label11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
    "route1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals ?"])},
    "route2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate this App ?"])},
    "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "update": {
      "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
      "Maried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maried"])},
      "Divorced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divorced"])},
      "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adamawa"])},
      "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East"])},
      "FN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Far North"])},
      "CE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North West"])},
      "WR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West"])},
      "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South West"])}
    }
  },
  "jobRequest": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to be acknoledged on this plateform as:"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "btn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepeted"])},
    "btn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
    "nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Request"])},
      "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users who can offer service"])}
    }
  },
  "pRequestN": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Requests"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users who linked up with Experts"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update My Requests"])},
    "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "createResearch": {
    "l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Description"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Knowledge and experience on Sexual and Gender Based Violence (SGBV)."])},
    "l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have anyone in the family or in the community that has experience SGBV in any form (rape, intimate partner violence, sex for marks, revenge porn (posting their naked pics or videos online), early child marriage?"])},
    "op1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "op2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you share a story of anyone you know who had experienced any of the forms of SGBV and how the situation was handled."])},
    "l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you think providing educative talks through school clubs and community radios programs can reduce the incidence of SGBV cases? Why?"])},
    "l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if a girl is wearing a more revealing dress where some of her body parts such as breast etc. are exposed and gets harassed or rape, who do you think is to be blamed. Why?"])},
    "l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a girl is walks at night and raped or harassed, do you think it’s her fault?"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Crisis"])},
    "l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How has the crisis affected the rate of SGBV in the community?"])},
    "l8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have emergency lines or report system that you can rely on?"])},
    "l9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you think the pandemic Covid has affected the occurrence rate of SGBV?"])},
    "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture"])},
    "l10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What myths or superstitious or spiritual beliefs exists around rape or intimate partner violence or homosexuality etc.?"])},
    "l11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you think you have rights over your partner, and over your partners body for any reason? Why?"])},
    "l12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want sex and your partner is not in the mood, do you have the right to force him/her?"])},
    "l13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it right for a one partner to beat up his/her partner?"])},
    "l14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you give some of the factors that lead to early child marriage?"])},
    "l15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you think it’s ok for a parent to marry off their child without the child’s concern? Why?"])},
    "l16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have there been any situations where your child or another CWD (child with disability) has been sexually abused or violently attacked? Tell us about it."])},
    "l17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us how parental education can put an end to SGBV?"])},
    "l18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you think are some of the barriers that make victims of SGBV hesitant to speak up?"])},
    "l19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you think that there is any justification for any form of SGBV?"])},
    "l20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you give recommendations on how we can fight against SGBV in the communities?"])},
    "l21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reponse"])},
    "l22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "notiNav": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time is up"])}
  },
  "myreports": {
    
  }
}