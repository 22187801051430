<template>
<div class="marker">
    <i class="fas fa-map-marker-alt"></i>
</div>


</template>
<style>
.marker i{
    color: crimson;
    font-size: 1.2rem;
}
</style>