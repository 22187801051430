<template>
  <div></div>
  <router-view />
</template>

<style>
#app {
  /* font-family: "Squada One", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #0f1011; */
  margin: 0 auto;
}
body {
  /* margin: 0; */
  background: #fff;
}
i,
.login-profil-img,
box-icon {
  cursor: pointer;
}
option {
  background: white;
  border: 1px solid #cfd5d8;
  padding: 5px;
  widows: 100%;
}
button {
  cursor: pointer;
}
</style>
