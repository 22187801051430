export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour depuis "])},
  "Nav": {
    "gbvcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas de Violence Sexiste"])},
    "ReportAbuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un abus"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])}
  },
  "popup": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'emploi"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande du patient"])},
    "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une recherche"])},
    "vue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la recherche"])},
    "edu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation Créer"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rapports"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
  },
  "header": {
    "anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonyme"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commenter"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répondre"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuler"])}
  },
  "rating": {
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'il vous plaît noter cet App"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez votre avis"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENVOYER"])}
  },
  "delete": {
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr que vous voulez supprimer ?"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer"])},
    "btn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuler"])}
  },
  "report": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de cas"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'abus"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer"])},
    "pysical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["violence physique"])},
    "options": {
      "psychological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["violence psychologique"])},
      "stalking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traque"])},
      "mariage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mariages forcés"])},
      "sexual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["violence sexuelle"])},
      "rape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viol"])},
      "mutilation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mutilations génitales féminines"])},
      "sterilisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stérilisation forcée"])},
      "harassement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["harcèlement sexuel"])},
      "abortion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avortement forcé"])}
    },
    "option2": {
      "Prentive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" palliatifs préventifs"])},
      "Consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" conseiller juridique"])},
      "Medical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez un médecin"])},
      "Psychologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le psychologue"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])}
    }
  },
  "jobSeekers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'emploi"])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
    "options": {
      "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cycliste"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin"])},
      "psychologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychologue"])},
      "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseiller juridique"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent de santé"])}
    }
  },
  "education": {
    "Nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu éducatif"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
      "readM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire la suite"])}
    },
    "updates": {
      "lable1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'éducation"])},
      "lable2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "la4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])}
    }
  },
  "demandes": {
    "clinical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander l'aide de la clinique"])},
    "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant juridique à la demande"])},
    "bike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycliste à la demande"])},
    "Psychological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un spécialiste de la santé psychologique"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "myRequest": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour mes demandes"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])}
  },
  "help": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste de la santé"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste en santé psychologique"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseillers juridiques"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cyclistes"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes"])}
  },
  "professionals": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs pouvant offrir  service"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis:"])},
    "h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible le: "])},
    "nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels"])},
      "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])}
    }
  },
  "profile": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le profil"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du profil"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "label5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État civil"])},
    "label6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "label7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
    "label8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région de résidence"])},
    "label9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
    "label10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "label11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicité"])},
    "route1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels ?"])},
    "route2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer l'application ?"])},
    "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
    "update": {
      "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
      "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
      "Single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célibataire"])},
      "Maried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marié"])},
      "Divorced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divorcé"])},
      "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adamaoua"])},
      "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est"])},
      "FN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrême nord"])},
      "CE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nord"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nord Ouest"])},
      "WR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouest"])},
      "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sud"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sud Ouest"])}
    }
  },
  "jobRequest": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite travailler sur cette plateforme en tant que :"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclin"])},
    "btn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté"])},
    "btn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refusé"])},
    "nav": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'emploi"])},
      "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs pouvant offrir service"])}
    }
  },
  "pRequestN": {
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes des patients"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs qui se sont connectés avec des experts"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre mes demandes à jour "])},
    "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mettre à jour"])}
  },
  "createResearch": {
    "l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'emplacement"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Connaissances et expérience sur la violence sexuelle  basée sur le genre (VSBG)."])},
    "l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous quelqu'un dans la famille ou dans la communauté qui a été victime de violence sexuelle ou sexiste sous quelque forme que ce soit (viol, violence conjugale, sexe pour des marques, vengeance pornographique (publication de ses photos ou vidéos nues en ligne), mariage précoce ?"])},
    "op1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "op2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouvez-vous partager l'histoire de quelqu'un que vous connaissez qui a vécu l'une des formes de violence sexuelle et sexiste et comment la situation a été gérée."])},
    "l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez-vous que proposer des causeries éducatives par le biais de clubs scolaires et de programmes de radios communautaires peut réduire l'incidence des cas de violence sexuelle et sexiste ? Pourquoi?"])},
    "l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si une fille porte une robe plus révélatrice où certaines parties de son corps comme la poitrine, etc. sont exposées et est harcelée ou violée, à qui pensez-vous qu'il faut blâmer? Pourquoi?"])},
    "l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si une fille marche la nuit et est violée ou harcelée, pensez-vous que c'est de sa faute ?"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Crise"])},
    "l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment la crise a-t-elle affecté le taux de violence sexuelle et sexiste dans la communauté ?"])},
    "l8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous des lignes d'urgence ou un système de signalement sur lequel vous pouvez compter ?"])},
    "l9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez-vous que la pandémie de Covid a affecté le taux d'occurrence des violences sexuelles et sexistes ?"])},
    "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture"])},
    "l10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels mythes ou croyances superstitieuses ou spirituelles existent autour du viol, de la violence conjugale ou de l'homosexualité, etc. ?"])},
    "l11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez-vous avoir des droits sur votre partenaire et sur le corps de votre partenaire pour une raison quelconque ? Pourquoi?"])},
    "l12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez du sexe et que votre partenaire n'est pas d'humeur, avez-vous le droit de le forcer ?"])},
    "l13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-il juste pour un partenaire de battre son partenaire ?"])},
    "l14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouvez-vous donner quelques-uns des facteurs qui conduisent au mariage précoce des enfants ?"])},
    "l15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez-vous qu'il est acceptable pour un parent de marier son enfant sans que celui-ci s'en soucie ? Pourquoi?"])},
    "l16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y a-t-il eu des situations où votre enfant ou un autre  a été abusé sexuellement ou violemment agressé ? Dis nous à propos de cela."])},
    "l17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous comment l'éducation parentale peut mettre fin à la violence sexuelle et sexiste ?"])},
    "l18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon vous, quels sont les obstacles qui font que les victimes de violence sexuelle et sexiste hésitent à s'exprimer ?"])},
    "l19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez-vous qu'il existe une justification à une forme quelconque de violence sexuelle et sexiste ?"])},
    "l20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouvez-vous donner des recommandations sur la façon dont nous pouvons lutter contre la violence sexuelle et sexiste dans les communautés ?"])},
    "l21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["response"])},
    "l22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "notiNav": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps est écoulé"])}
  },
  "myreports": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rapports"])}
  }
}