<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-#A999050F">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"
        ><div class="endsgbv">
          <p style="fontweight: bold">endsgbv</p>
          <!-- <div class="vl"></div> -->
          <img src="../../../../assets/images/lg.png" alt="" /></div
      ></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li class="nav-item mr-6">
            <a aria-current="page" href="#features">Features</a>
          </li>
          <li class="nav-item mr-6">
            <a target="_blank" href="https://endsgbv.medium.com">Education</a>
          </li>
          <li class="nav-item mr-6">
            <router-link to="/our-stories">Stories</router-link>
            <!-- <a target="_blank" href="https://endsgbv.medium.com">Stories</a> -->
          </li>
          <li class="nav-item mr-6">
            <a target="_blank" href="https://www.ebaseafrica.org/team">Team</a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <button class="btn app-btn">
            <a
              class="applink"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.vinsight.kwikklik"
              >GET APP</a
            >
          </button>
        </ul>
      </div>
    </div>
  </nav>
  <section>
    <div class="container-lg">
      <div class="container">
        <div class="d-flex mt-5 heading">
          <img
            src="../../../../assets/images/logo-circle.png"
            class="me-2"
            alt=""
          />
          <div class="">
            <small class="lh-1">EndSGBV</small>
            <div class="">
              <small class="lh-1 date">Feb4 6 min read</small>
            </div>
          </div>
        </div>

        <p class="fs-3 mt-3 fw-bold">GBV MEETs its Match</p>
      </div>
      <div class="container">
        <div class="body-img">
          <img
            src="../../../../assets/images/storyimg.jpeg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="mt-5">
          <p class="fs-4 fw-bold">What is Gender Based Violence (GBV)?</p>
          <p class="fs-5">
            Gender Based Violence is any act of violence committed against a
            person due to the person’s <b>gender</b> and results in
            <b>physical, psychological, sexual or economic harm.</b> Victims of
            Gender Based Violence suffer health consequences like
            <b
              >forced and unwanted pregnancies, STDs, trauma and
              stigmatization.</b
            >
          </p>
          <p class="fs-5">
            In different regions in Cameroon, there are several conflicts. In
            the Northern regions <b>Boko Haram</b> constantly attacks civilians
            and children and kids are generally the target. Some girls are being
            kidnapped and forced into marriage by these terrorists and these
            kinds of acts are catalyzed by the fact that the girl child is
            considered to be less of a human as compared to the boy child. Apart
            from these<b>Boko Haram</b> attacks; certain
            <b>cultural and religious</b>
            beliefs have permitted gender-based violence for a long time.
          </p>
          <p class="fs-5">
            In the <b>North West and South West regions</b> of Cameroon, there
            is a war between
            <b>separatist fighters and the Cameroon army;</b> which lives again
            women and children very vulnerable as they are usually the targets.
            Young girls are targeted by
            <b> some irresponsible armed forces and forced to have sex.</b> They
            are many times forced into relationships and in general are at high
            risk to falling in the hands of sex predators.
          </p>
          <p class="fs-5">
            In the <b>Eastern region,</b> many young girls have been obliged to
            get into the <b>sex trade in order to make a living,</b> hence
            leaving them very exposed to gender based violence, in addition to
            refugees from the <b>Central African Republic,</b> have contributed
            in making this region very unsafe to live in; especially as the
            refugee camps are poorly funded. This leads to girls been targets of
            sexual gender-based violence by locals and even the male refugees.
          </p>
          <p class="fs-5">
            Although we have cited some regions, in the other regions, we still
            witness gender-based violence; especially in the form of
            <b>
              intimate partner violence, rape, sexually transmitted marks issued
              to female students,</b
            >
            just to name a few.
          </p>
          <p class="fs-5">
            Those whose rights are violated are also faced with weak health
            systems <b>(mental, clinical, psycho social)</b> are weak in
            capacity and resources, coupled with weak legal systems;
            materialized as unenforced laws and ignorance.
          </p>
          <p class="fs-4 fw-bold">
            Using Technology to help reduce Gender Based Violence.
          </p>
          <p class="fs-5">
            In the context of general insecurity (In the North West and South
            West Regions, East Region, North Region),
            <b>misinformation, stigmatization and weak legal systems,</b> it
            becomes very common to get cases of young girls and women who have
            been abused and left helpless without any proper care. Nonetheless,
            with the increasingly high adoption of
            <b> mobile phones</b> especially with <b>GPS capability</b> and
            increasing internet access, these young girls now have an
            opportunity to be heard by people who truly care about them and who
            can help them at least receive the care they need. Availability of
            ‘<b>Okadas’</b> i.e., bike riders make it easy for anyone to get
            first aid care even in the most interior zones. National policies
            providing social care and social system structures have been
            developed over the years hence making it easier for Gender Based
            Violence victims to receive care.
          </p>
          <p class="fs-5">
            Thanks to advancements in technology, and availability of local app
            development firms and institutes like
            <b> eBASE (Effective Basic Services) Africa</b> in collaboration
            with <b>Vinsight technologies, Geovation Collaborations</b>, and
            <b>Silicon Mountain</b> and with funding from the
            <b>French embassy Cameroon under the PISCCA program,</b> we have the
            capacity of building technological solutions which can provide a way
            for Victims to report anonymously, get help and get free educational
            content. Stakeholders too can now in real time gain access to real
            time information on Gender Based Violence cases and based on this
            information take necessary measures to solve this wicked problem.
          </p>
          <p class="fs-4 fw-bold">
            How does the Kwikklik App help reduce and prevent Gender Based
            Violence (GBV)?.
          </p>
          <p class="fs-5">
            It is made of a <b>reporting unit</b> platform which uses an
            innovative approach in which mobile phones can be used to report 3D
            (<b>GPS, Image, Voice, Time</b>) data of GBV cases. A
            <b>mapping unit</b>
            wherein stakeholders can view data of anonymized GBV victims. A
            <b>preventive unit</b> where crowd sourced data and artificial
            intelligence (AI) will be used to help notify people in a certain
            area about the risks of GBV.
          </p>
          <p class="fs-5">
            In cases where the harm has already been done, the
            <b> consultancy unit</b> permits victims get in contact easily with
            experts (<b>medical, psychology and legal</b>) and emergency units
            (<b>‘Okadas’</b>), so that they can be taken immediate care of.
          </p>
          <p class="fs-5">
            In order to create awareness and prevent future cases of GBV, the
            <b>education unit</b> will help sensitize users about measures of
            prevention of GBV and also emergency steps to be taken when such
            crimes are committed.
          </p>
          <p class="fs-4 fw-bold">How does the Kwikklik App work?</p>
          <p class="fs-5">
            This App is multi-platform an can be accessed on most devices.
          </p>
          <p class="fs-5">
            You can access the app by clicking on <b>Get App</b> on the landing
            page:
          </p>
        </div>
        <p class="fs-5">
          <b
            ><a href="https://www.endsgbv.org">https://www.endsgbv.org</a>. Once
            you gain access to the app, you can then:</b
          >
        </p>
        <p class="fs-4 fw-bold">Sign Up:</p>
        <p class="fs-5">
          In case you have yet created an account, once You get to fill in all
          necessary information to get registered as a user.
        </p>
        <br />
        <p class="fs-4">Note:</p>
        <br />
        <ul>
          <li class="fs-5">
            <p>
              <span class="fw-bold me-2">*</span> Make sure to put in a valid
              email address
            </p>
          </li>
          <li class="fs-4">
            <p><span class="fw-bold me-2">*</span>Email addresses are unique</p>
          </li>
          <li class="fs-5">
            <p><span class="fw-bold me-2">*</span> Usernames are unique</p>
          </li>
          <li class="fs-5">
            <p>
              <span class="fw-bold me-2">*</span> The password in the
              verification box must match the original password
            </p>
          </li>
          <li class="fs-5">
            <p>
              <span class="fw-bold me-2">*</span>You must accept the terms and
              agreements.
            </p>
          </li>
        </ul>
      </div>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap1.jpeg"
          class="img-fluid"
          alt=""
        />
        <p class="text-center mt-3" style="font-size: 14px">
          Signing Up on the Kwikklik Platform
        </p>
      </div>
      <p class="fs-4 fw-bold">Sign In:</p>
      <p class="fs-5">
        In case you already have an account, you just put in your credentials
        and gain access to the platform.
      </p>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap2.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">Homepage</p>
      <p class="fs-5">
        Now logged in, the first page you see is the home page which contains
        different user’s reports and you can choose to comment or view any of
        these reports by simply clicking on them.
      </p>

      <div class="body-img">
        <img
          src="../../../../assets/images/ap3.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>

      <p class="fs-4 fw-bold">Reporting</p>
      <p class="fs-5">
        Once you click on the reporting icon, you have this report abuse page
        which comes up. You can insert <b> textual details</b> about your case,
        do an optional <b> voice recording,</b> upload an optional
        <b>image,</b> select the case type or input a custom
        <b>case type,</b> choose the <b>date of abuse</b> and then choose
        whether to get <b>preventive palliatives,</b> get in contact with a
        medical doctor, legal consultant or psychologist
      </p>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap4.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">Education Content Page</p>
      <p class="fs-5">
        Any user can gain access to <b>free educational content</b> by clicking
        on the education icon, and then choosing to view either videos or
        articles. The videos can be viewed on this platform or on the platform
        on which they are hosted.
      </p>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap5.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">Getting Help (Medical, Psychological, Legal)</p>
      <p class="fs-5">
        All users can get access to
        <b
          >Preventive Palliatives, Medical practitioners, legal consultants and
          psychologists,</b
        >
        in just <b>one click.</b> You can also view your requests and know their
        state (whether accepted, declined or pending)
      </p>

      <div class="body-img">
        <img
          src="../../../../assets/images/ap6.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">Map view</p>
      <p class="fs-5">
        Reports apart from being just viewed as plain text and multimedia files,
        can be viewed on a map, with different colors representing different
        case types
      </p>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap7.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">User Profile</p>
      <p class="fs-5">
        You can view and update profile information, by selecting My account,
        when you click on the top left icon. Then if you want to update, just
        click on edit profile, and once the update is done, click on update.
      </p>

      <div class="body-img">
        <img
          src="../../../../assets/images/ap8.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4">App review</p>
      <div class="body-img">
        <img
          src="../../../../assets/images/ap9.jpeg"
          class="img-fluid"
          alt=""
        />
      </div>
      <p class="fs-4 fw-bold">Conclusion</p>
      <p class="fs-5">
        In this blogpost, we have discussed how a technological solution like
        the Kwikklik App can help in the prevention of GBV and taking care of
        GBV victims. We have also seen how to use this App effectively.
      </p>
      <p class="fs-5">Let’s all take care :)</p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style  scoped>
a {
  text-decoration: none;
  color: black;
}
.navbar {
  background: #a39835a3;
}
.endsgbv {
  display: flex;
  align-items: center;
}

.endsgbv p {
  margin: 0px 5px;
}
.endsgbv img {
  height: 40px;
}
.applink {
  color: #0472448f;
  text-decoration: none;
}
.app-btn {
  border: 1px solid grey;

  outline: none;
  color: #0472448f;
  font-weight: bold;
  border-radius: 15px;
}
.app-btn:hover {
  border: none;
  background: #a39835a3;
  outline: none;
  color: black;
  border-radius: 15px;
}
.nav-item a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}
a:hover {
  color: #0472448f;
}
.heading img {
  width: 50px;
  height: 50px;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
}
.date {
  color: #c5c1c1;
  font-size: 0.8rem;
}
.body-img img {
  margin: 0 auto;
}
</style>